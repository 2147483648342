import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom';
import {
  Box,
  Container,
  Avatar,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TableFooter,
  TablePagination,
  TextField,
  Divider,
  Alert
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../skeletons/UserListSkelet';
import { useDelete, useGet, usePost } from '../../API/request';
import { useConfirm } from '../../components/Confirm/index';
import { BallTriangle } from 'react-loader-spinner';
import '../../styles/All.css';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import { ChevronLeft } from 'react-feather';

const ClientsList = () => {
  const { category } = useParams();

  const confirm = useConfirm();
  const navigate = useNavigate();

  const getU = useGet();
  const deleteU = useDelete();

  const [isLoaded, setIsLoaded] = useState(true);

  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);

  const [alert, setAlert] = useState({
    txt: '',
    isVisible: false,
    type: 'error'
  });

  const showAlert = (type, text) => {
    setAlert({
      txt: text,
      type,
      isVisible: true
    });

    setTimeout(() => {
      setAlert({
        txt: text,
        type,
        isVisible: false
      });
    }, 2500);
  };

  const loadClients = () => {
    setIsLoaded(true);

    let endpoint = `clients?page=${
      page + 1
    }&limit=${limit}&category=${category}`;

    getU(endpoint)
      .then((resp) => {
        if (resp.status === 'success') {
          setData(resp.data.clients);
          setCount(resp.data.currentCount || 0);
        } else {
          showAlert(
            'error',
            'Произошла ошибка при получении клиентов, повторите попытку позже'
          );
        }
      })
      .catch((err) => {
        console.log(err.response);
        setData([]);
        setCount(0);
      })
      .finally(() => {
        setIsLoaded(false);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const onDelete = (id) => {
    confirm({
      title: 'Удаление',
      content: 'Вы уверены, что хотите удалить клиента?',
      onConfirm: () => {
        deleteU(`clients/${id}`)
          .then((resp) => {
            if (resp.status === 'success') {
              loadClients();
            } else {
              showAlert(
                'error',
                'Произошла ошибка при попытке удалить клиента'
              );
            }
          })
          .catch((e) => {
            showAlert('error', e.response.data.message);
          });
      }
    });
  };

  useEffect(() => {
    loadClients();
  }, [page, limit]);

  if (isLoaded) {
    return (
      <div className="loader">
        <BallTriangle
          height="100"
          width="100"
          color="grey"
          ariaLabel="loading"
        />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Клиенты</title>
      </Helmet>
      <Box className="headerWrapper">
        <Box className="headerTitle">
          <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
            Назад
          </Button>
          Клиенты категории {category}
        </Box>
      </Box>
      <Box
        sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}
      >
        <Container maxWidth={false}>
          <Alert
            severity={alert.type}
            style={{
              display: alert.isVisible ? 'flex' : 'none',
              marginBottom: 10
            }}
          >
            {alert.txt}
          </Alert>
          <Box sx={{ pt: 3 }}>
            <Card>
              <PerfectScrollbar>
                <Box sx={{ minWidth: 500 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>id</TableCell>
                        <TableCell>Имя</TableCell>
                        <TableCell>Почта</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.map((item) => (
                        <TableRow hover key={item.id}>
                          <TableCell>{item?.id || '---'}</TableCell>
                          <TableCell>{item?.name || '---'}</TableCell>
                          <TableCell>{item?.email || '---'}</TableCell>
                          <TableCell>
                            <Box sx={{ display: 'flex' }}>
                              <Box sx={{ display: 'flex' }}>
                                <Box>
                                  <Button
                                    color="error"
                                    variant="contained"
                                    onClick={() => onDelete(item.id)}
                                  >
                                    Удалить
                                  </Button>
                                </Box>
                              </Box>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          labelRowsPerPage={
                            <span>Кол-во строк на странице:</span>
                          }
                          rowsPerPageOptions={[10, 20, 30, 40, 50]}
                          colSpan={7}
                          count={count}
                          rowsPerPage={limit}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeLimit}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </Box>
              </PerfectScrollbar>
            </Card>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default ClientsList;
