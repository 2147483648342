import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Button,
  TextField,
  CardContent
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { useNavigate, useParams } from 'react-router-dom';
import { useGet, usePost, usePut } from '../../API/request';
import React, { useEffect, useState } from 'react';
import { BallTriangle } from 'react-loader-spinner';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Alert from '@material-ui/core/Alert';

const MailSendingInput = () => {
  const { category } = useParams();
  const navigate = useNavigate();
  const postU = usePost();
  const getU = useGet();

  const [templatesKeys, setTemplatesKeys] = useState([]);

  const [isLoaded, setIsLoaded] = useState(true);

  const [values, setValues] = useState({
    key: '',
    interval: '300'
  });
  const [errors, setErrors] = useState({
    key: false,
    interval: false
  });

  const [submitDisabled, setSubmitDisabled] = useState(false);

  const [alert, setAlert] = useState({
    txt: '',
    isVisible: false,
    type: 'error'
  });

  const showAlert = (type, text) => {
    setAlert({
      txt: text,
      type,
      isVisible: true
    });

    setTimeout(() => {
      setAlert({
        txt: text,
        type,
        isVisible: false
      });

      setSubmitDisabled(false);
    }, 1400);
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
    setErrors({
      ...errors,
      [event.target.name]: false
    });
  };

  const validate = () => {
    let validComplete = true;
    let formErrors = { ...errors };

    if (values.key === '') {
      validComplete = false;
      formErrors.key = false;
      showAlert('error', 'Поле Шаблон письма не должно быть пустым');
    }
    if (values.interval === '') {
      validComplete = false;
      formErrors.interval = false;
      showAlert('error', 'Поле Интервал не должно быть пустым');
    }
    if (!Number.isInteger(+values.interval)) {
        validComplete = false;
        formErrors.interval = false;
        showAlert('error', 'Поле Интервал должно быть числом');
      }
    if (values.interval <= 0) {
      validComplete = false;
      formErrors.interval = false;
      showAlert('error', 'Поле Интервал не должно быть положительным');
    }

    setErrors(formErrors);
    return validComplete;
  };

  const submit = async () => {
    if (validate()) {
      setSubmitDisabled(true);

      const data = {
        category: category,
        key: values.key,
        interval: values.interval
      };

      postU('email/category', data)
        .then((resp) => {
          if (resp.status === 'success') {
            showAlert('success', 'Начата отправка писем');
          } else {
            showAlert('error', 'Произошла ошибка при создании отправки');
          }
        })
        .catch((err) => {
          showAlert('error', err.response.data.message);
        })
        .finally(() => {
          setSubmitDisabled(false);
        });
    }
  };

  const getTemplates = () => {
    setIsLoaded(true);
    getU(`template/keys?page=1&limit=0`)
      .then((resp) => {
        if (resp.status === 'success') {
          setTemplatesKeys(resp.data.keys);
        } else {
          showAlert('error', 'Произошла ошибка при загрузке шаблонов писем');
        }
      })
      .catch(() => {
        showAlert('error', 'Произошла ошибка при загрузке шаблонов писем');
      })
      .finally(() => {
        setIsLoaded(false);
      });
  };

  useEffect(() => {
    getTemplates();
  }, []);

  if (isLoaded) {
    return (
      <div className="loader">
        <BallTriangle
          height="100"
          width="100"
          color="grey"
          ariaLabel="loading"
        />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Отправка писем</title>
      </Helmet>
      <Box className="headerWrapper">
        <Box className="headerTitle">
          <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
            Назад
          </Button>
          Отправка писем по категории {category}
        </Box>
      </Box>
      <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
        <Container maxWidth={false}>
          <Box sx={{ pt: 2 }}>
            <form>
              <Card>
                <CardContent sx={{ position: 'relative' }}>
                  <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                    <InputLabel id="templateId">
                      Выберите шаблон письма
                    </InputLabel>
                    <Select
                      labelId="templateId"
                      name="key"
                      value={values.key}
                      label="Выберите шаблон письма"
                      onChange={handleChange}
                    >
                      {templatesKeys?.map((item) => (
                        <MenuItem value={item.key}>{item.key}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <TextField
                    fullWidth
                    label="Интервал, c."
                    margin="normal"
                    name="interval"
                    type="number"
                    InputProps={{
                      inputProps: { min: 1 }
                    }}
                    value={values.interval}
                    variant="outlined"
                    error={errors.interval}
                    onChange={handleChange}
                  />

                  <Alert
                    severity={alert.type}
                    style={{ display: alert.isVisible ? 'flex' : 'none' }}
                  >
                    {alert.txt}
                  </Alert>
                </CardContent>
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={submit}
                    disabled={submitDisabled}
                  >
                    Добавить
                  </Button>
                </Box>
              </Card>
            </form>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default MailSendingInput;
