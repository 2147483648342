import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useSearchParams } from 'react-router-dom';
import {
  Box,
  Container,
  Avatar,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TableFooter,
  TablePagination,
  TextField,
  Divider,
  Alert
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../skeletons/UserListSkelet';
import { useDelete, useGet, usePost } from '../../API/request';
import { useConfirm } from '../../components/Confirm/index';
import { BallTriangle } from 'react-loader-spinner';
import '../../styles/All.css';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';

const ClientsCategories = () => {
  const confirm = useConfirm();
  const getU = useGet();
  const postU = usePost();
  const deleteU = useDelete();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isLoaded, setIsLoaded] = useState(true);

  const [modalErrors, setModalErrors] = useState({
    isOpen: false,
    errors: null
  });

  const [disableLoadTable, setDisableLoadTable] = useState(false);
  const [loadedTable, setLoadedTable] = useState('');

  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);

  const [alert, setAlert] = useState({
    txt: '',
    isVisible: false,
    type: 'error'
  });

  const showAlert = (type, text) => {
    setAlert({
      txt: text,
      type,
      isVisible: true
    });

    setTimeout(() => {
      setAlert({
        txt: text,
        type,
        isVisible: false
      });
    }, 2500);
  };

  const XMLUpLoaded = (event) => {
    setLoadedTable(event.target.files[0]);
  };

  const onPostTable = () => {
    if (loadedTable !== '') {
      setDisableLoadTable(true);
      console.log('here');
      let data = new FormData();
      data.append('table', loadedTable);

      postU(`clients/xlsx`, data)
        .then((resp) => {
          if (resp.status === 'success') {
            showAlert('success', 'Вы успешно загрузили таблицу');
            if (resp.data.errors) {
              setModalErrors({
                isOpen: true,
                errors: resp.data.errors
              });
            }
            setLoadedTable('');
            loadCategories();
          } else {
            showAlert(
              'error',
              'Произошла ошибка при попытке загрузить таблицу'
            );
          }
        })
        .catch((e) => {
          showAlert('error', e.response.data.message);
        })
        .finally(() => {
          setDisableLoadTable(false);
        });
    }
  };

  const loadCategories = () => {
    setIsLoaded(true);

    let endpoint = `clients/categories/?page=${page + 1}&limit=${limit}`;

    getU(endpoint)
      .then((resp) => {
        if (resp.status === 'success') {
          setData(resp.data.categories);
          setCount(resp.data.currentCount || 0);
        } else {
          showAlert(
            'error',
            'Произошла ошибка при получении категорий клиентов, повторите попытку позже'
          );
        }
      })
      .catch((err) => {
        console.log(err.response);
        setData([]);
        setCount(0);
      })
      .finally(() => {
        setIsLoaded(false);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  useEffect(() => {
    onPostTable();
  }, [loadedTable]);

  const onDelete = (category) => {
    confirm({
      title: 'Удаление',
      content: 'Вы уверены, что хотите удалить всех клиентов в категории?',
      onConfirm: () => {
        deleteU(`clients/categories/${category}`)
          .then((resp) => {
            if (resp.status === 'success') {
              loadCategories();
            } else {
              showAlert(
                'error',
                'Произошла ошибка при попытке удалить категорию'
              );
            }
          })
          .catch((e) => {
            showAlert('error', e.response.data.message);
          });
      }
    });
  };

  useEffect(() => {
    loadCategories();
  }, [page, limit]);

  if (isLoaded) {
    return (
      <div className="loader">
        <BallTriangle
          height="100"
          width="100"
          color="grey"
          ariaLabel="loading"
        />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Клиенты</title>
      </Helmet>
      <Box className="headerWrapper">
        <Box className="headerTitle">Категории клиентов</Box>
      </Box>
      <Box
        sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}
      >
        <Container maxWidth={false}>
          <Alert
            severity={alert.type}
            style={{
              display: alert.isVisible ? 'flex' : 'none',
              marginBottom: 10
            }}
          >
            {alert.txt}
          </Alert>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Box sx={{ marginLeft: 2 }}>
              <input
                type="file"
                accept=".xlsx"
                style={{ display: 'none' }}
                id="clientsXMLTable"
                disabled={disableLoadTable}
                onChange={XMLUpLoaded}
              />
              <label htmlFor="clientsXMLTable">
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  component="span"
                  disabled={disableLoadTable}
                >
                  Загрузить таблицу клиентов
                </Button>
              </label>
            </Box>
          </Box>
          <Box sx={{ pt: 3 }}>
            <Card>
              <PerfectScrollbar>
                <Box sx={{ minWidth: 500 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Название</TableCell>
                        <TableCell>Количество клиентов</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.map((item) => (
                        <TableRow hover key={item.category}>
                          <TableCell>{item?.category || '---'}</TableCell>
                          <TableCell>{item?.clientsCount || '---'}</TableCell>
                          <TableCell>
                            <Box sx={{ display: 'flex' }}>
                              <Box sx={{ display: 'flex' }}>
                                <Link
                                  to={`/app/clients/categories/send/${item?.category}`}
                                >
                                  <Button color="success" variant="contained">
                                    Отправка
                                  </Button>
                                </Link>
                                <Box sx={{ ml: 2 }}>
                                  <Link
                                    to={`/app/clients/categories/${item?.category}`}
                                  >
                                    <Button color="primary" variant="contained">
                                      Инфо.
                                    </Button>
                                  </Link>
                                </Box>
                                <Box sx={{ ml: 2 }}>
                                  <Button
                                    color="error"
                                    variant="contained"
                                    onClick={() => onDelete(item.category)}
                                  >
                                    Удалить
                                  </Button>
                                </Box>
                              </Box>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          labelRowsPerPage={
                            <span>Кол-во строк на странице:</span>
                          }
                          rowsPerPageOptions={[10, 20, 30, 40, 50]}
                          colSpan={7}
                          count={count}
                          rowsPerPage={limit}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeLimit}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </Box>
              </PerfectScrollbar>
            </Card>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default ClientsCategories;
